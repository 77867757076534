import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import EmailReachHandler from "./providers/contexts/EmailReachHandler";
import AuthProvider from "./providers/contexts/AuthContext";
import App from "./App";
import { QueryClientProvider } from "react-query";
import { EmailReachProvider } from "./providers/contexts/EmailReachContext";
import { QueryClient } from "react-query";
import { globalStyles } from "./themes/constants";
import { GlobalStyles } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { lightTheme } from "./themes/lightTheme";
import { BrowserRouter as Router } from "react-router-dom";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <Suspense>
    <Router>
      <GlobalStyles styles={globalStyles} />
      <ThemeProvider theme={lightTheme}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <EmailReachProvider>
              <EmailReachHandler>
                <App />
              </EmailReachHandler>
            </EmailReachProvider>
          </AuthProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </Router>
  </Suspense>
  // </React.StrictMode>
);
