import { useState, lazy, Suspense } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  TextField,
  Box,
  Alert,
} from "@mui/material";
import { useEmailReach } from "../../../../providers/contexts/EmailReachContext";

const DeleteButton = lazy(() => import("./DeleteButton"));

const DeleteModal = ({
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  setIsSettingsOpen,
}: any) => {
  const { activeAccount } = useEmailReach();
  const [deleteInput, setDeleteInput] = useState("");

  const accountIdentifier = activeAccount?.account_identifier;

  const handleDeleteDisabled = () => {
    if (deleteInput === accountIdentifier) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Dialog open={isDeleteModalOpen}>
      <DialogContent sx={{ margin: "10px", width: "500px" }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant={"h5"} sx={{ marginBottom: "20px" }}>
            Delete account?
          </Typography>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography>Are you sure you want to delete</Typography>
            <Box display="flex" flexDirection="row" marginBottom="10px">
              <Typography sx={{ fontWeight: "bold" }}>
                "{accountIdentifier}"
              </Typography>
              <Typography>?</Typography>
            </Box>
          </Box>
          <Alert severity="error" sx={{ marginBottom: "10px" }}>
            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
              Warning
            </Typography>
            <Typography variant="subtitle2">
              This action cannot be undone. This will permanently delete the
              account "{accountIdentifier}" and all associated data.
            </Typography>
          </Alert>
          <TextField
            autoFocus
            margin="dense"
            id="accountIdentifier"
            label="Enter account name to confirm"
            fullWidth
            variant="outlined"
            value={deleteInput}
            onChange={(e) => {
              setDeleteInput(e.target.value);
            }}
          />
          <Box
            display="flex"
            justifyContent="space-evenly"
            width="100%"
            marginTop="20px"
          >
            <Button
              variant="outlined"
              onClick={() => {
                setIsDeleteModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <Suspense>
              <DeleteButton
                setIsDeleteModalOpen={setIsDeleteModalOpen}
                handleDeleteDisabled={handleDeleteDisabled}
                setIsSettingsOpen={setIsSettingsOpen}
              />
            </Suspense>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteModal;
