import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Avatar,
  IconButton,
  Popper,
  Grow,
  Card,
  CardContent,
  List,
  ListItemButton,
  ListItemText,
  Divider,
  ClickAwayListener,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LogoutButton from "../../../components/LogoutButton";
import OrganizationName from "../../../components/OrganizationName";

const UserMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { user, logout } = useAuth0();

  if (!user) return null;

  const handleUserClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsOpen(!isOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    setIsOpen(false);
    logout();
  };

  const handleClickAway = () => {
    setIsOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <Box
          display="flex"
          onClick={handleUserClick}
          borderRadius={2}
          boxShadow={
            isOpen
              ? "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
              : 0
          }
          py={0.5}
          pl={2}
          sx={{
            cursor: "pointer",
            transition:
              "background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out",
            userSelect: "none",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.02)",
            },
          }}
        >
          <Box display="flex" alignItems="center">
            <Avatar
              sx={{ width: 32, height: 32 }}
              src={user.picture}
              alt={user.nickname}
            />
            {/* <Typography color="text.primary">{user.name}</Typography> */}
          </Box>
          <IconButton size="small">
            <ExpandMoreIcon
              sx={{
                fontSize: "1.1rem",
                transform: isOpen ? "rotate(180deg)" : undefined,
                transition: "transform 0.2s ease-in-out",
              }}
            />
          </IconButton>
        </Box>
        <Popper
          open={isOpen}
          anchorEl={anchorEl}
          placement="bottom-start"
          transition
          sx={{ zIndex: "9999" }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Card
                sx={{
                  mt: 1,
                  borderRadius: "8px",
                  boxShadow: 2,
                  width: 200,
                }}
              >
                <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
                  <List dense sx={{ p: 0 }}>
                    <ListItemButton>
                      <ListItemText
                        primary={<OrganizationName />}
                        secondary={user.email}
                      />
                    </ListItemButton>
                    <Divider />
                    <LogoutButton onClick={handleLogout} />
                  </List>
                </CardContent>
              </Card>
            </Grow>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default UserMenu;
