import { AppBar, Box, Toolbar } from "@mui/material";
import Logo from "../../../components/Logo/Logo";
import ActiveSite from "../AccountSelectorMenu/AccountSelectorMenu";
import UserMenu from "./UserMenu";

const AppBarCustom = () => {
  return (
    <AppBar
      color="default"
      elevation={0}
      sx={{
        height: 64,
        zIndex: 2,
        boxShadow: "0 2px 5px 0 rgba(0, 0, 0, .08)",
      }}
    >
      <Toolbar>
        <Box
          flex={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Logo size={120} />
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <ActiveSite />
            <Box display="flex" alignItems="center">
              <UserMenu />
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarCustom;
