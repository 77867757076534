import {
  typography,
  breakpoints,
  direction,
  spacing,
  shape,
  bloxValues,
  bloxColors,
  bloxIconColors,
} from "./constants";

// Things that can apply to both light and dark themes go here in baseTheme.
export const bloxBaseTheme: any = {
  breakpoints: breakpoints,
  direction: direction,
  spacing: spacing,
  shape: shape,
  typography: typography,
  blox: {
    values: bloxValues,
    colors: bloxColors,
    iconColors: bloxIconColors,
  },

  // Note: any "components" overrides in any *Theme.ts files applies to all instances of that component across the app.
  // If we want to override a component for a specific component/feature/css selector, use the sx prop.
  // Generally use 'components' to set global default colors, outlines, fonts, etc, or removing certain default styles from RA/MUI.
  // Avoid avoid any overrides that can affect layout / positioning.
  // https://mui.com/material-ui/customization/how-to-customize/

  components: {
    MuiStack: {
      defaultProps: {
        useFlexGap: true, // Opt-in to use css flex gap. (~93% browser support as of 11-01-2023)
      },
      styleOverrides: {
        root: {
          m: 0,
          p: 0,
          margin: 0,
          padding: 0,
        },
      },
    },

    MuiTooltip: {
      defaultProps: {
        arrow: true, // Show tooltip arrow by default.
        PopperProps: {
          popperOptions: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -8], // Move tooltips closer to the element they're attached to.
                },
              },
            ],
          },
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          color: "inherit", // Makes all <Link component={RouterLink}> text black/white in light or dark mode.
          textDecoration: "none",
        },
      },
    },

    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: "6px",
          width: "100%",
          backgroundColor: "#DCF5FF",
        },
        barColorPrimary: {
          backgroundColor: "#00A3E1",
        },
      },
    },

    MuiSnackbarContent: {
      styleOverrides: {
        message: {
          color: "#FFFFFF",
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },

    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },

    MuiFilledInput: {
      styleOverrides: {
        underline: {
          "&:before": {
            borderBottom: "none",
          },
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 5,
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          "& td, & th": {
            border: 0,
            zIndex: 1,
          },
          "&:nth-of-type(odd)": {
            backgroundColor: "#f7fafe",
          },
          "&:hover td": {
            backgroundColor: "#f0f5fe",
          },
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    },
  },
};

export const baseTheme = bloxBaseTheme;
