import { forwardRef, Ref } from "react";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import ExitIcon from "@mui/icons-material/ExitToApp";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = forwardRef<Ref<HTMLLIElement>, any>((props, ref) => {
  const { logout } = useAuth0();
  const handleClick = () => logout();

  return (
    <MenuItem onClick={handleClick} ref={ref} {...props} aria-label="Logout">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        alignContent="center"
        spacing={2}
      >
        <ExitIcon fontSize="small" />
        <Typography variant="body1">Logout</Typography>
      </Stack>
    </MenuItem>
  );
});

export default LogoutButton;
