import { useState } from "react";
import {
  Typography,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import SettingsScreen from "../../../screens/settingsPanel/SettingsPanel";
import ViewListIcon from "@mui/icons-material/ViewList";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CampaignIcon from "@mui/icons-material/Campaign";
import SettingsIcon from "@mui/icons-material/Settings";
import MessageIcon from "@mui/icons-material/Message";

export const MenuCustom = () => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const ListItemLink = ({ to, primaryText, IconComponent }: any) => {
    const location = useLocation();
    const selected = to === location.pathname;
    return (
      <ListItemButton
        component={Link}
        to={to}
        selected={selected}
        sx={{
          borderRadius: 1,
          padding: "5px 10px",
          margin: "5px 0",
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: "40px",
          }}
        >
          <IconComponent sx={{ fontSize: 20 }} />
        </ListItemIcon>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            fontWeight: selected ? "bold" : "normal",
            opacity: selected ? 0.8 : 0.6,
          }}
        >
          {primaryText}
        </Typography>
      </ListItemButton>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        boxSizing: "border-box",
        py: 1,
        flexGrow: 1,
      }}
    >
      <Box flexGrow={1}>
        <Typography
          variant="overline"
          fontWeight="bold"
          sx={{ marginLeft: "10px", opacity: ".5" }}
        >
          Main Navigation
        </Typography>

        <Box>
          <List>
            <ListItemLink
              to="/contactlists"
              primaryText="Lists"
              IconComponent={ViewListIcon}
            />
            <ListItemLink
              to="/contacts"
              primaryText="Contacts"
              IconComponent={AccountBoxIcon}
            />
            <ListItemLink
              to="/campaigns"
              primaryText="Campaigns"
              IconComponent={CampaignIcon}
            />
            <ListItemLink
              to="/sent-messages"
              primaryText="Sent Messages"
              IconComponent={MessageIcon}
            />
          </List>
        </Box>
      </Box>

      <Box
        id="menu-footer"
        sx={{
          paddingTop: "20px",
          opacity: ".7",
          "& a": { fontSize: ".9rem" },
        }}
      >
        <ListItemButton
          onClick={() => setIsSettingsOpen(true)}
          sx={{
            borderRadius: 1,
            padding: "5px 10px",
          }}
        >
          <ListItemIcon>
            <SettingsIcon sx={{ fontSize: 20 }} />
          </ListItemIcon>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
            }}
          >
            Settings
          </Typography>
        </ListItemButton>

        <SettingsScreen
          isSettingsOpen={isSettingsOpen}
          setIsSettingsOpen={setIsSettingsOpen}
        />
      </Box>
    </Box>
  );
};

export default MenuCustom;
