import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  FunctionComponent,
} from "react";
import SnackbarAlert, {
  SnackbarSeverity,
} from "../../components/SnackbarAlert/SnackbarAlert";

// Define the types for the context
type SnackbarContextType = {
  openSnackbar: (message: string, severity: SnackbarSeverity) => void;
  closeSnackbar: () => void;
};

type SnackbarProviderProps = {
  children: React.ReactNode;
};

// Create the context with an initial undefined value
const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);

// Define the provider component
export const SnackbarProvider: FunctionComponent<SnackbarProviderProps> = ({
  children,
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    SnackbarSeverity | undefined
  >(undefined);

  const openSnackbar = useCallback(
    (message: string, severity: SnackbarSeverity) => {
      setSnackbarMessage(message);
      setSnackbarSeverity(severity);
      setSnackbarOpen(true);
    },
    []
  );

  const closeSnackbar = useCallback(() => {
    setSnackbarOpen(false);
  }, []);

  // Value to be passed to context consumers
  const contextValue = {
    openSnackbar,
    closeSnackbar,
  };

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      <SnackbarAlert
        snackbarOpen={snackbarOpen}
        closeSnackbar={closeSnackbar}
        snackbarMessage={snackbarMessage}
        snackbarSeverity={snackbarSeverity}
      />
    </SnackbarContext.Provider>
  );
};

// Hook to use the snackbar functionality
export const useSnackbar = (): SnackbarContextType => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};
