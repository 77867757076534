import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";
import { useAuth0 } from "@auth0/auth0-react";
import jwtDecode from "jwt-decode";

const OrganizationName = () => {
  const [orgName, setOrgName] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getAccessTokenSilently();
      if (token) {
        const decodedToken: any = jwtDecode(token.toString());
        setOrgName(decodedToken.organization.display_name);
      }
    };

    fetchToken();
  }, [getAccessTokenSilently]);

  return (
    <Box display="flex">
      <Box margin="5px">
        <CorporateFareRoundedIcon />
      </Box>
      <Box margin="5px">
        <Typography variant="body1">{orgName}</Typography>
      </Box>
    </Box>
  );
};

export default OrganizationName;
