import { createContext, useCallback, useContext, useMemo } from "react";
import { fetchUtils } from "react-admin";
import { useAuth0 } from "@auth0/auth0-react";
import { EmailReachCustomDataProviderType } from "../types/dataProvider";
import { EmailReachContext } from "./EmailReachContext";
import { StringifyOptions } from "query-string";

export interface EmailReachDataContextType {
  dataProvider: EmailReachCustomDataProviderType;
}

export const EmailReachDataProvidersContext = createContext<
  EmailReachDataContextType | undefined
>(undefined);

export const useEmailReachDataProvider = () => {
  const context = useContext(EmailReachDataProvidersContext);
  if (!context) {
    throw new Error(
      "useEmailReachDataProvider must be used within a EmailReachDataProvider"
    );
  }
  return context;
};

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export const EmailReachDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const getAuthenticatedHeaders = useCallback(async (): Promise<Headers> => {
    try {
      const token = await getAccessTokenSilently();
      const headers = new Headers({ Accept: "application/json" });
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        return headers;
      }
    } catch (error) {
      console.error("Error in getTokenSilently:", error);
    }
    throw new Error("Unable to retrieve token");
  }, [getAccessTokenSilently]);

  const httpClient = useCallback(
    async (url: string, options: fetchUtils.Options = {}): Promise<any> => {
      const executeRequest = async (): Promise<any> => {
        try {
          const requestHeaders = await getAuthenticatedHeaders();
          const response = await fetchUtils.fetchJson(url, {
            ...options,
            headers: requestHeaders,
          });
          return response;
        } catch (error: any) {
          if (error.body) {
            try {
              const errorBody = await error.body;
              if (
                errorBody.details === "Refresh token is invalid" &&
                error.status === 400
              ) {
                await handleReAuthFlow();
                return executeRequest(); // Retry the request after re-authentication
              }
            } catch (parseError) {
              console.error("Error parsing error response:", parseError);
            }
          }
          throw error;
        }
      };

      const handleReAuthFlow = async (): Promise<void> => {
        let isReAuthInProgress = false;
        let reAuthPromise: Promise<void> | null = null;

        if (!isReAuthInProgress) {
          isReAuthInProgress = true;

          // Dispatch event to show the dialog
          window.dispatchEvent(new CustomEvent("require-reauth"));

          // Create a new promise that will resolve when the re-authentication is completed
          reAuthPromise = new Promise<void>((resolve) => {
            const handleCloseReAuth = () => {
              window.removeEventListener("close-reauth", handleCloseReAuth);
              isReAuthInProgress = false;
              resolve();
            };
            window.addEventListener("close-reauth", handleCloseReAuth);
          });
        }

        // Wait for the re-authentication to complete
        await reAuthPromise;
      };

      return executeRequest();
    },
    [getAuthenticatedHeaders]
  );

  // Utility function to determine the data key based on the resource
  const getResourceDataKey = (resource: string): string => {
    // Map the resource to the data key
    const resourceKeyMap: { [key: string]: string } = {
      contactlists: "lists",
      contacts: "contacts",
      // Add more mappings as needed
    };

    return resourceKeyMap[resource] || "defaultKey";
  };

  const context = useContext(EmailReachContext);

  const { activeAccount } = context || {};

  const contextValue = useMemo(() => {
    const queryOptions: StringifyOptions = {
      arrayFormat: "bracket",
      skipEmptyString: true,
      skipNull: true,
    };
    // -----------------------------------------------
    // ---------------- DATA PROVIDER ----------------
    // -----------------------------------------------
    const dataProvider: EmailReachCustomDataProviderType = {
      getList: async (resource: string, params: any) => {
        try {
          // Building the query parameters
          const query = {
            account_uuid: activeAccount?.account_uuid,
            ...params,
          };

          // Constructing the URL for the API request
          const url = `${apiUrl}/${resource}?${fetchUtils.queryParameters(
            query,
            queryOptions
          )}`;

          // Making the API request using httpClient
          const { json } = await httpClient(url, { method: "GET" });

          // Returning the data and total count
          return {
            data: {
              ...json,
            },
            count: json.details.count,
          };
        } catch (error) {
          console.error("[dataProvider] getList() error:", error?.toString());
          throw error;
        }
      },
      getInfiniteList: async (resource: string, params: any) => {
        try {
          if (!activeAccount?.account_uuid) {
            throw new Error("No active account found");
          }
          // Constructing the query parameters
          const query = {
            account_uuid: activeAccount?.account_uuid,
            ...params,
          };

          // Constructing the URL for the API request
          const url = `${apiUrl}/${resource}?${fetchUtils.queryParameters(
            query,
            queryOptions
          )}`;

          // Making the API request using httpClient
          const { json } = await httpClient(url, { method: "GET" });

          if (!json.details) {
            throw new Error("No data received from the API");
          }

          // Determine the key under which the data is located
          const dataKey = getResourceDataKey(resource);
          if (!json.details[dataKey]) {
            throw new Error(
              `No data found for key '${dataKey}' in the API response`
            );
          }

          const data = json.details[dataKey];
          const nextCursor = json.details.pagination?.token;

          // Transforming the data to include necessary identifiers
          const dataWithIds = data.map((item: any) => ({
            ...item,
            id: item.uuid,
          }));

          // Returning the data and the next cursor
          return {
            data: dataWithIds,
            count: json.details.count,
            nextCursor,
          };
        } catch (error) {
          console.error(
            "[dataProvider] getInfiniteList() error:",
            error?.toString()
          );
          throw error;
        }
      },

      getOne: async (resource: string, params: any) => {
        const parameters = JSON.stringify(params);
        try {
          const query = {
            account_uuid: activeAccount?.account_uuid,
            format: "blox",
            parameters,
          };
          const url = `${apiUrl}/${resource}/${
            params.id
          }?${fetchUtils.queryParameters(query, queryOptions)}`;

          const { json } = await httpClient(url, { method: "GET" });

          return {
            data: {
              ...json,
            },
          };
        } catch (error) {
          console.error("[dataProvider] getOne() error:", error?.toString());
          throw error;
        }
      },
      update: async (resource: string, params: any) => {
        try {
          // Constructing the URL for the API request
          const url = params.id
            ? `${apiUrl}/${resource}/${params.id}/`
            : `${apiUrl}/${resource}/`;

          const body = {
            account_uuid: activeAccount?.account_uuid,
            ...params.data,
          };

          // Making the API request using httpClient
          const { json } = await httpClient(url, {
            method: "PUT",
            body: JSON.stringify(body),
          });

          // Returning the data
          return {
            data: {
              ...json,
            },
          };
        } catch (error) {
          console.error("[dataProvider] update() error:", error?.toString());
          throw error;
        }
      },

      create: async (resource: string, params: any) => {
        const query = {
          account_uuid: activeAccount?.account_uuid,
        };
        const body = {
          ...params.data,
          ...query,
        };
        try {
          // Constructing the URL for the API request
          const url = `${apiUrl}/${resource}/?${fetchUtils.queryParameters(
            query,
            queryOptions
          )}`;

          // Making the API request using httpClient
          const { json } = await httpClient(url, {
            method: "POST",
            body: JSON.stringify(body),
          });

          // Returning the data
          return {
            data: {
              ...json,
            },
          };
        } catch (error) {
          console.error("[dataProvider] create() error:", error?.toString());
          throw error;
        }
      },

      delete: async (resource: string, params: any) => {
        const query = {
          account_uuid: activeAccount?.account_uuid,
        };

        const body = {
          ...params.data,
          ...query,
        };

        try {
          // Constructing the URL for the API request
          const url = `${apiUrl}/${resource}/${params.id}`;

          // Making the API request using httpClient
          await httpClient(url, {
            method: "DELETE",
            body: JSON.stringify(body),
          });

          // Returning the data
          return {
            data: params.previousData,
          };
        } catch (error) {
          console.error("[dataProvider] delete() error:", error?.toString());
          throw error;
        }
      },

      deleteAccount: async (resource: string, params: any) => {
        const query = {
          account_uuid: activeAccount?.account_uuid,
        };

        const body = {
          ...params.data,
          ...query,
        };

        try {
          // Constructing the URL for the API request
          const url = `${apiUrl}/${resource}`;

          // Making the API request using httpClient
          await httpClient(url, {
            method: "DELETE",
            body: JSON.stringify(body),
          });

          // Returning the data
          return {
            data: params.previousData,
          };
        } catch (error) {
          console.error("[dataProvider] delete() error:", error?.toString());
          throw error;
        }
      },

      reAuthenticateAccount: async (resource: string, params: any) => {
        const body = {
          ...params,
        };

        try {
          // Constructing the URL for the API request
          const url = `${apiUrl}/${resource}`;

          // Making the API request using httpClient
          await httpClient(url, {
            method: "POST",
            body: JSON.stringify(body),
          });

          // Returning the data
          return {
            data: params.previousData,
          };
        } catch (error) {
          console.error(
            "[dataProvider] reAuthenticateAccount() error:",
            error?.toString()
          );
          throw error;
        }
      },

      updateAccount: async (params: any) => {
        try {
          // Constructing the URL for the API request
          const url = `${apiUrl}/accounts`;

          const body = {
            account_uuid: activeAccount?.account_uuid,
            ...params,
          };

          // Making the API request using httpClient
          const { json } = await httpClient(url, {
            method: "PUT",
            body: JSON.stringify(body),
          });

          // Returning the data
          return {
            data: {
              ...json,
            },
          };
        } catch (error) {
          console.error(
            "[dataProvider] updateAccount() error:",
            error?.toString()
          );
          throw error;
        }
      },
    };
    return {
      dataProvider,
    };
  }, [httpClient, activeAccount]);

  return (
    <EmailReachDataProvidersContext.Provider value={contextValue}>
      {children}
    </EmailReachDataProvidersContext.Provider>
  );
};
