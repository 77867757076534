import React from "react";
import { Button, Box, Typography, FormControl } from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import constantContactLogo from "../../../../../assets/images/constant-contact-logo-icon.webp";
import ConstantContactProvider from "../../../../../providers/ContstantContactProvider";

interface VendorDetails {
  vendorLoginCode: string;
  vendorId: string;
  vendorName: string;
  vendorLoginCodeExpired: boolean;
}

interface StepTwoProps {
  vendorDetails: VendorDetails;
}

const VendorLogin: React.FC<StepTwoProps> = ({ vendorDetails }) => {
  const oAuth2Config = {
    baseUrl: process.env.REACT_APP_BASE_URL || "",
    clientId: process.env.REACT_APP_CONSTANT_CONTACT_CLIENT_ID || "",
  };

  const oAuth2 = ConstantContactProvider(oAuth2Config);

  const handleLogin = () => {
    // Store minimal required state before redirect
    localStorage.setItem("vendorId", vendorDetails.vendorId);
    localStorage.setItem("vendorName", vendorDetails.vendorName);
    localStorage.setItem("activeStep", "1");
    oAuth2.login();
  };
  return (
    <Box>
      <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
        {vendorDetails.vendorLoginCode ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
          >
            <CheckRoundedIcon
              sx={{ fontSize: 60, color: "success.main", mr: 4 }}
            />
            <Box>
              <Typography variant="h5" sx={{ mb: 1 }}>
                Successfully Logged In
              </Typography>
              <Typography variant="subtitle1">
                You may now proceed to the next step to complete the account
                creation process.
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box display="flex" flexDirection="row">
            <Button
              variant="contained"
              onClick={handleLogin}
              startIcon={
                <Box
                  alt={`${vendorDetails.vendorName} Logo`}
                  src={constantContactLogo}
                  component="img"
                  height={"35px"}
                />
              }
              sx={{
                backgroundColor: "white !important",
                color: "primary.main",
                textTransform: "none",
                width: "100%",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                "&:hover": {
                  backgroundColor: "grey.100",
                },
              }}
            >
              <Typography variant="body2" padding="10px" color="black">
                Log In with {vendorDetails.vendorName}
              </Typography>
            </Button>

            <Box sx={{ m: 2, display: "flex", flexDirection: "row" }}>
              <Box>
                <InfoRoundedIcon
                  sx={{
                    fontSize: "16px",
                    marginRight: "10px",
                    color: "text.secondary",
                  }}
                />
              </Box>
              <Box component="span" display="flex" alignItems="center">
                <Typography
                  variant="caption"
                  color="text.secondary"
                  fontSize="0.8rem"
                  fontWeight="500"
                >
                  Clicking the button will redirect you to{" "}
                  {vendorDetails.vendorName}'s login page. You will return here
                  upon successful login.
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </FormControl>
    </Box>
  );
};

export default VendorLogin;
