import SettingsPanel from "../../features/settings-menu/index";

const SettingsScreen = ({ isSettingsOpen, setIsSettingsOpen }: any) => {
  return (
    <SettingsPanel
      isSettingsOpen={isSettingsOpen}
      setIsSettingsOpen={setIsSettingsOpen}
    />
  );
};

export default SettingsScreen;
