import { Box, Typography, Button, Dialog, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Logo from "./Logo/Logo";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Wizard from "../features/account-creation/index";
import OAuth2Callback from "./utils/OAuth2Callback";
import { useEffect } from "react";

const NoAccountsComponent = () => {
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const renderWizard = () => {
    if (
      window.location.pathname === "/account/create" ||
      localStorage.getItem("oauth2_code")
    ) {
      return <Wizard />;
    } else {
      return null;
    }
  };

  const handleCallback = () => {
    if (window.location.pathname === "/constantcontact-callback") {
      return <OAuth2Callback />;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (localStorage.getItem("oauth2_code")) {
      navigate("/account/create");
    }
  }, [navigate]);

  return (
    <>
      {window.location.pathname !== "/account/create" && (
        <Dialog open fullScreen={fullScreen}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            p={4}
            sx={{
              minWidth: fullScreen ? "auto" : 400,
              minHeight: fullScreen ? "auto" : 500,
            }}
          >
            <Box
              mb={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="h4" gutterBottom>
                Welcome to
              </Typography>
              <Box mb={3}>
                <Logo size={200} />
              </Box>
              <Typography variant="subtitle1" textAlign="center" mb={3}>
                You will need to set up an account to continue.
              </Typography>
            </Box>
            <Box width="100%">
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={() => navigate("/account/create")}
                sx={{ mb: 2 }}
              >
                Set up account
              </Button>
              <Button
                variant="outlined"
                size="medium"
                fullWidth
                onClick={() => logout()}
              >
                Not you? Log out
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
      {renderWizard()}
      {handleCallback()}
    </>
  );
};

export default NoAccountsComponent;
