import { createTheme } from "@mui/material/styles";
import { deepmerge } from "@mui/utils";
import { baseTheme } from "./baseTheme";
import {
  bloxColors,
  bloxValues,
  bloxIconColors,
  lightPalette,
} from "./constants";

// Overrides for the light theme. These are merged with the baseTheme.
export const light: any = {
  palette: lightPalette,
  blox: {
    values: bloxValues,
    colors: bloxColors,
    iconColors: bloxIconColors,
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          "& :not([class*=-color]) :not([class*=icon-])": {
            color: "#5B575E",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          color: "#5B575E",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          "&.rich-text-editor-toolbar": {
            backgroundColor: "#feffff",
            "& .MuiButton-root:not([class*='-disabled'])": { color: "#333333" },
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },
  },
};

export const lightTheme = createTheme(deepmerge(baseTheme, light));
