import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  OutlinedInput,
  CircularProgress,
  Typography,
  SelectChangeEvent,
} from "@mui/material";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { useQuery } from "react-query";
import { useEmailReachDataProvider } from "../../../../../providers/contexts/EmailReachDataProvidersContext";

interface Vendor {
  id: string;
  name: string;
}

interface VendorDetails {
  vendorLoginCode: string;
  vendorId: string;
  vendorName: string;
  vendorLoginCodeExpired: boolean;
}

interface VendorSelectionProps {
  accountDetails: {
    accountIdentifier: string;
    vendorDetails: VendorDetails;
  };
  setVendorDetails: (vendorDetails: VendorDetails) => void;
}

const VendorSelection = ({
  accountDetails,
  setVendorDetails,
}: VendorSelectionProps) => {
  const [vendorOptions, setVendorOptions] = useState<Vendor[]>([]);

  const { dataProvider } = useEmailReachDataProvider();

  const fetchVendors = async () => {
    const vendors = await dataProvider.getList("config/vendors");
    return vendors;
  };

  const {
    data: vendorDetails,
    isLoading,
    error,
  } = useQuery("vendors", fetchVendors, {
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (vendorDetails) {
      const options: Vendor[] = vendorDetails.data.details?.map(
        (details: any) => ({
          id: details.uuid,
          name: details.name,
        })
      );
      setVendorOptions(options);
    }
  }, [vendorDetails]);

  const handleVendorSelectionChange = (event: SelectChangeEvent<string>) => {
    const selectedId = event.target.value;
    const selectedVendor = vendorOptions.find(
      (vendor) => vendor.id === selectedId
    );

    if (selectedVendor) {
      setVendorDetails({
        ...accountDetails.vendorDetails,
        vendorId: selectedVendor.id,
        vendorName: selectedVendor.name,
      });
    }
  };

  return (
    <Box>
      <FormControl
        fullWidth
        margin="normal"
        variant="outlined"
        sx={{ zIndex: 2 }}
      >
        <InputLabel id="vendor-select-label">Email Service Provider</InputLabel>
        <Select
          labelId="vendor-select-label"
          value={accountDetails.vendorDetails.vendorId || ""}
          onChange={handleVendorSelectionChange}
          input={<OutlinedInput label="Email Service Provider" />}
          sx={{
            borderRadius: "4px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            "&:hover": {
              borderColor: "secondary.main",
              backgroundColor: "action.hover",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "primary.main",
              transition: "border-color 300ms",
            },
            ".MuiSelect-icon": {
              color: "action.active",
            },
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            sx: {
              maxHeight: "300px",
              "& .MuiPaper-root": {
                borderRadius: "4px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              },
            },
          }}
        >
          {isLoading ? (
            <MenuItem>
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                alignContent="center"
                justifyContent="center"
                gap={2}
              >
                <Typography variant="body2">Loading vendors...</Typography>
                <CircularProgress size={24} />
              </Box>
            </MenuItem>
          ) : error ? (
            <MenuItem>
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                alignContent="center"
                justifyContent="center"
              >
                <Typography variant="body2" color="error">
                  Error loading vendors
                </Typography>
              </Box>
            </MenuItem>
          ) : (
            vendorOptions.map((vendor) => (
              <MenuItem key={vendor.id} value={vendor.id}>
                {vendor.name}
              </MenuItem>
            ))
          )}
        </Select>
        <FormHelperText>
          <Box component="span" display="flex" alignItems="center">
            <InfoRoundedIcon sx={{ fontSize: "16px", marginRight: "10px" }} />
            <Typography variant="caption">
              Select the email service provider you want to import your
              information from.
            </Typography>
          </Box>
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

export default VendorSelection;
