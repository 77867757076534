import React from "react";
import {
  Box,
  FormControl,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import InfoIconRounded from "@mui/icons-material/InfoRounded";
import { TIMEZONES } from "../../../../../constants";

// Update the props interface to match your centralized state management
interface AccountDetailsProps {
  accountDetails: {
    accountIdentifier: string;
    timezone: string;
  };
  setAccountDetails: (updatedDetails: any) => void;
}

const AccountDetails: React.FC<AccountDetailsProps> = ({
  accountDetails,
  setAccountDetails,
}) => {
  const onHandleAccountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAccountDetails({
      ...accountDetails,
      accountIdentifier: event.target.value,
    });
  };

  const onHandleTimezoneChange = (event: SelectChangeEvent<string>) => {
    const selectedTimezoneValue = event.target.value as string;
    const selectedTimezone = TIMEZONES.find(
      (tz) => tz.value === selectedTimezoneValue
    );

    setAccountDetails({
      ...accountDetails,
      timezone: selectedTimezoneValue,
      timezoneLabel: selectedTimezone ? selectedTimezone.label : "",
    });
  };

  return (
    <Box>
      <FormControl fullWidth margin="normal" variant="outlined">
        <TextField
          label="Account name"
          variant="outlined"
          value={accountDetails.accountIdentifier}
          onChange={onHandleAccountChange}
          sx={{
            "& .MuiOutlinedInput-root": {
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            },
          }}
        />
        <FormHelperText>
          <Box component="span" display="flex" alignItems="center">
            <InfoIconRounded
              sx={{ fontSize: "16px", marginRight: "10px", color: "action" }}
            />
            Enter a name for your account. This will be used to identify your
            account.
          </Box>
        </FormHelperText>
      </FormControl>

      <FormControl fullWidth margin="normal" variant="outlined">
        <InputLabel id="timezone-select-label">Timezone</InputLabel>
        <Select
          labelId="timezone-select-label"
          value={accountDetails.timezone || ""}
          onChange={onHandleTimezoneChange}
          input={<OutlinedInput label="Timezone" />}
          sx={{
            borderRadius: "4px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            "&:hover": {
              borderColor: "secondary.main",
              backgroundColor: "action.hover",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "primary.main",
              transition: "border-color 300ms",
            },
            ".MuiSelect-icon": {
              color: "action.active",
            },
          }}
        >
          {TIMEZONES.map((timezone, index) => (
            <MenuItem key={index} value={timezone.value}>
              {timezone.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          <Box component="span" display="flex" alignItems="center">
            <InfoIconRounded
              sx={{ fontSize: "16px", marginRight: "10px", color: "action" }}
            />
            Choose the timezone that matches your location.
          </Box>
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

export default AccountDetails;
