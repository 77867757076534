export const TIMEZONES = [
  { label: "International Date Line West (UTC-12:00)", value: "Etc/GMT+12" },
  { label: "American Samoa (UTC-11:00)", value: "Pacific/Pago_Pago" },
  { label: "Hawaii (UTC-10:00)", value: "Pacific/Honolulu" },
  { label: "Alaska (UTC-09:00)", value: "America/Anchorage" },
  {
    label: "Pacific Time (US & Canada) (UTC-08:00)",
    value: "America/Los_Angeles",
  },
  { label: "Mountain Time (US & Canada) (UTC-07:00)", value: "America/Denver" },
  { label: "Central Time (US & Canada) (UTC-06:00)", value: "America/Chicago" },
  {
    label: "Eastern Time (US & Canada) (UTC-05:00)",
    value: "America/New_York",
  },
  { label: "Atlantic Time (Canada) (UTC-04:00)", value: "America/Halifax" },
  { label: "Newfoundland (UTC-03:30)", value: "America/St_Johns" },
  {
    label: "Buenos Aires (UTC-03:00)",
    value: "America/Argentina/Buenos_Aires",
  },
  { label: "Mid-Atlantic (UTC-02:00)", value: "Atlantic/South_Georgia" },
  { label: "Azores (UTC-01:00)", value: "Atlantic/Azores" },
  { label: "London (UTC+00:00)", value: "Europe/London" },
  { label: "Berlin (UTC+01:00)", value: "Europe/Berlin" },
  { label: "Athens (UTC+02:00)", value: "Europe/Athens" },
  { label: "Moscow (UTC+03:00)", value: "Europe/Moscow" },
  { label: "Tehran (UTC+03:30)", value: "Asia/Tehran" },
  { label: "Dubai (UTC+04:00)", value: "Asia/Dubai" },
  { label: "Kabul (UTC+04:30)", value: "Asia/Kabul" },
  { label: "Karachi (UTC+05:00)", value: "Asia/Karachi" },
  { label: "New Delhi (UTC+05:30)", value: "Asia/Kolkata" },
  { label: "Kathmandu (UTC+05:45)", value: "Asia/Kathmandu" },
  { label: "Dhaka (UTC+06:00)", value: "Asia/Dhaka" },
  { label: "Yangon (UTC+06:30)", value: "Asia/Yangon" },
  { label: "Bangkok (UTC+07:00)", value: "Asia/Bangkok" },
  { label: "Beijing (UTC+08:00)", value: "Asia/Shanghai" },
  { label: "Tokyo (UTC+09:00)", value: "Asia/Tokyo" },
  { label: "Adelaide (UTC+09:30)", value: "Australia/Adelaide" },
  { label: "Sydney (UTC+10:00)", value: "Australia/Sydney" },
  { label: "New Caledonia (UTC+11:00)", value: "Pacific/Noumea" },
  { label: "Auckland (UTC+12:00)", value: "Pacific/Auckland" },
  { label: "Samoa (UTC+13:00)", value: "Pacific/Apia" },
  { label: "Line Islands (UTC+14:00)", value: "Pacific/Kiritimati" },
];
