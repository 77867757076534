import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ContantContactProvider from "../../providers/ContstantContactProvider";

const OAuth2Callback = () => {
  const navigate = useNavigate();

  // Initialize Constant Contact Provider with the required configuration
  const oAuth2Config = {
    baseUrl: process.env.REACT_APP_BASE_URL || "",
    clientId: process.env.REACT_APP_CONSTANT_CONTACT_CLIENT_ID || "",
  };

  const authProvider = ContantContactProvider(oAuth2Config);

  useEffect(() => {
    authProvider
      .handleCallback()
      .then((code: string) => {
        localStorage.setItem("oauth2_code", code);
        navigate("/account/create");
      })
      .catch((err: Error) => {
        console.error("OAuth2 Callback Error:", err);
        navigate("/error");
      });
  }, [navigate, authProvider]);

  return null;
};

export default OAuth2Callback;
