import { useEffect, useCallback } from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
} from "@mui/material";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { useTheme } from "@mui/material/styles";
import { useEmailReach } from "../providers/contexts/EmailReachContext";
import serviceProviderLogo from "../assets/images/constant-contact-logo-icon.webp";
import ConstantContactProvider from "../providers/ContstantContactProvider";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";

const ReAuthDialog = () => {
  const theme = useTheme();
  const {
    showReAuthModal,
    setShowReAuthModal,
    activeAccount,
    isReAuthSuccess,
    isReAuthError,
    resetReAuth,
  } = useEmailReach();
  const serviceProviderName = activeAccount?.vendor_name || "Service Provider";

  // Initialize OAuth2 configuration and provider
  const oAuth2Config = {
    baseUrl: process.env.REACT_APP_BASE_URL || "",
    clientId: process.env.REACT_APP_CONSTANT_CONTACT_CLIENT_ID || "",
  };
  const oAuth2 = ConstantContactProvider(oAuth2Config);

  const handleContinue = () => {
    localStorage.removeItem("isReAuth");
    localStorage.removeItem("oauth2_code");
    setShowReAuthModal(false);
    resetReAuth();
  };

  const handleReLogin = useCallback(() => {
    localStorage.setItem("isReAuth", "true");
    oAuth2.login();
  }, [oAuth2]);

  useEffect(() => {
    return () => {
      resetReAuth();
    };
  }, [resetReAuth]);

  const handleClose = () => {
    setShowReAuthModal(false);
    resetReAuth();
  };

  return (
    <Dialog
      open={showReAuthModal}
      aria-labelledby="reauth-dialog-title"
      disableEscapeKeyDown={isReAuthSuccess || isReAuthError}
    >
      <DialogTitle id="reauth-dialog-title">
        {isReAuthSuccess || isReAuthError ? "" : "Re-authentication Required"}
      </DialogTitle>
      <DialogContent sx={{ pt: 2 }}>
        {isReAuthSuccess ? (
          <Box sx={{ textAlign: "center", py: 4 }}>
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                width: 80,
                height: 80,
                borderRadius: "50%",
                backgroundColor: "success.main",
                color: "success.contrastText",
                mb: 3,
              }}
            >
              <CheckIcon sx={{ fontSize: 48 }} />
            </Box>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Re-authentication Successful
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              Your account with {serviceProviderName} has been successfully
              reconnected.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleContinue}
            >
              Continue
            </Button>
          </Box>
        ) : isReAuthError ? (
          <Box sx={{ textAlign: "center", py: 4 }}>
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                width: 80,
                height: 80,
                borderRadius: "50%",
                backgroundColor: "error.main",
                color: "error.contrastText",
                mb: 3,
              }}
            >
              <ErrorIcon sx={{ fontSize: 48 }} />
            </Box>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Re-authentication Error
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              An error occurred during the re-authentication process. Please try
              again or contact support if the problem persists.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleReLogin}
              sx={{ textTransform: "none" }}
            >
              Try Again
            </Button>
          </Box>
        ) : (
          <>
            <Typography variant="body1" sx={{ mb: 4 }}>
              We are unable to authenticate your account with{" "}
              {serviceProviderName}. Please log in to reconnect your account.
            </Typography>
            <Button
              variant="contained"
              onClick={handleReLogin}
              startIcon={
                <img
                  alt={`${serviceProviderName} Logo`}
                  src={serviceProviderLogo}
                  height="35"
                />
              }
              fullWidth
              sx={{
                backgroundColor: "#fff",
                color: theme.palette.text.primary,
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#f5f5f5",
                },
              }}
            >
              Log In with {serviceProviderName}
            </Button>
            <Box sx={{ m: 2, display: "flex", flexDirection: "row" }}>
              <InfoRoundedIcon
                sx={{
                  fontSize: "16px",
                  marginRight: "10px",
                  color: "text.secondary",
                }}
              />
              <Typography variant="caption" color="text.secondary">
                Clicking the button will redirect you to {serviceProviderName}'s
                login page. You will return here upon successful login.
              </Typography>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ flexDirection: "column", mx: 3, mt: -2, mb: 2 }}>
        <Button
          onClick={handleClose}
          sx={{ color: theme.palette.text.secondary, textTransform: "none" }}
        >
          {isReAuthSuccess || isReAuthError
            ? "Close"
            : "Re-authenticate at a later time."}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReAuthDialog;
