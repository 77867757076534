import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  DialogActions,
  IconButton,
  Alert,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { grey } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";

import VendorSelection from "./Steps/VendorSelection";
import VendorLogin from "./Steps/VendorLogin";
import AccountDetails from "./Steps/AccountDetails";
import AccountReview from "./Steps/AccountReview";

interface VendorDetails {
  vendorLoginCode: string;
  vendorId: string;
  vendorName: string;
  vendorLoginCodeExpired: boolean;
}

interface AccountDetail {
  accountIdentifier: string;
  timezone: string;
  timezoneLabel: string;
  vendorDetails: VendorDetails;
}
interface InitialAccountState {
  open: boolean;
  activeStep: number;
  success: boolean;
  accountDetails: {
    accountIdentifier: string;
    timezone: string;
    timezoneLabel: string;
    vendorDetails: VendorDetails;
  };
}

const Wizard: React.FC = () => {
  const location = useLocation();
  const [state, setState] = useState<InitialAccountState>({
    open: false,
    activeStep: 0,
    success: false,
    accountDetails: {
      accountIdentifier: "",
      timezone: "",
      timezoneLabel: "",
      vendorDetails: {
        vendorLoginCode: "",
        vendorId: "",
        vendorName: "",
        vendorLoginCodeExpired: false,
      },
    },
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (state.accountDetails.vendorDetails.vendorLoginCode) {
      setInterval(() => {
        setState((prevState) => ({
          ...prevState,
          accountDetails: {
            ...prevState.accountDetails,
            vendorDetails: {
              ...prevState.accountDetails.vendorDetails,
              vendorLoginCodeExpired: true,
            },
          },
        }));
      }, 300000);
    }
  }, [state.accountDetails.vendorDetails.vendorLoginCode]);

  const handleClose = () => {
    setState({
      open: false,
      activeStep: 0,
      success: false,
      accountDetails: {
        accountIdentifier: "",
        timezone: "",
        timezoneLabel: "",
        vendorDetails: {
          vendorLoginCode: "",
          vendorId: "",
          vendorName: "",
          vendorLoginCodeExpired: false,
        },
      },
    });
    localStorage.removeItem("oauth2_code");
    localStorage.removeItem("vendorId");
    localStorage.removeItem("vendorName");
    navigate("/");
  };

  const handleNext = async () => {
    if (state.success) {
      await queryClient.fetchQuery("accounts");
      handleClose();
    } else {
      setState({ ...state, activeStep: state.activeStep + 1 });
    }
  };

  const handleBack = () => {
    setState({ ...state, activeStep: state.activeStep - 1 });
  };

  const updateVendorDetails = (vendorDetails: VendorDetails) => {
    setState((prevState) => ({
      ...prevState,
      accountDetails: {
        ...prevState.accountDetails,
        vendorDetails,
      },
    }));
  };

  const updateAccountDetails = (accountDetails: AccountDetail) => {
    setState((prevState) => ({
      ...prevState,
      accountDetails,
    }));
  };

  useEffect(() => {
    if (location.pathname === "/accounts/create") {
      setState((prevState) => ({
        ...prevState,
        open: true,
      }));
    }
  }, [location]);

  useEffect(() => {
    const storedActiveStep = localStorage.getItem("activeStep");
    const oauth2Code = localStorage.getItem("oauth2_code");
    const storedVendorId = localStorage.getItem("vendorId");
    const storedVendorName = localStorage.getItem("vendorName");

    if (storedActiveStep || oauth2Code || storedVendorId) {
      setState((prevState) => ({
        ...prevState,
        activeStep: storedActiveStep
          ? parseInt(storedActiveStep, 10)
          : prevState.activeStep,
        accountDetails: {
          ...prevState.accountDetails,
          vendorDetails: {
            ...prevState.accountDetails.vendorDetails,
            vendorLoginCode:
              oauth2Code ||
              prevState.accountDetails.vendorDetails.vendorLoginCode,
            vendorId:
              storedVendorId || prevState.accountDetails.vendorDetails.vendorId,
            vendorName:
              storedVendorName ||
              prevState.accountDetails.vendorDetails.vendorName,
          },
        },
      }));

      // Remove the items from localStorage after updating the state
      localStorage.removeItem("activeStep");
      localStorage.removeItem("oauth2_code");
      localStorage.removeItem("vendorId");
      localStorage.removeItem("vendorName");
    }
  }, []);

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <VendorSelection
            accountDetails={state.accountDetails}
            setVendorDetails={updateVendorDetails}
          />
        );
      case 1:
        return (
          <VendorLogin vendorDetails={state.accountDetails.vendorDetails} />
        );
      case 2:
        return (
          <AccountDetails
            accountDetails={state.accountDetails}
            setAccountDetails={updateAccountDetails}
          />
        );
      case 3:
        return (
          <AccountReview
            accountDetails={state.accountDetails}
            setState={setState}
          />
        );
      default:
        return "Unknown step";
    }
  };

  const isNextButtonEnabled = () => {
    const { accountDetails } = state;
    if (state.accountDetails.vendorDetails.vendorLoginCodeExpired) {
      return false;
    }
    switch (state.activeStep) {
      case 0:
        return accountDetails.vendorDetails.vendorId.length > 0;
      case 1:
        return state.accountDetails.vendorDetails.vendorLoginCode.length > 0;
      case 2:
        return (
          accountDetails.accountIdentifier.length > 0 &&
          accountDetails.timezone.length > 0
        );
      case 3:
        return state.success;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (state.activeStep === 1) {
      const oauth2Code = localStorage.getItem("oauth2_code");
      if (oauth2Code) {
        updateVendorDetails({
          ...state.accountDetails.vendorDetails,
          vendorLoginCode: oauth2Code,
        });
        localStorage.removeItem("oauth2_code");
      }
    }
  }, [state.activeStep, state.accountDetails.vendorDetails]);

  return (
    <Box>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={true}
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
            overflow: "hidden",
            backgroundColor: "#fff",
            transition: "box-shadow 0.3s ease-in-out",
            minWidth: "700px",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "left",
            padding: "10px 24px",
            backgroundColor: grey[100],
            fontWeight: "500",
            fontSize: "1.3rem",
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          Account Setup
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Box>
            <Typography variant="subtitle1">
              Steps {state.activeStep + 1} of 4
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            padding: "20px 24px",
            paddingRight: "16px",
            position: "relative",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#BDBDBD",
              borderRadius: "3px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#9E9E9E",
            },
            scrollbarWidth: "thin",
            scrollbarColor: "#BDBDBD transparent",
          }}
        >
          {state.accountDetails.vendorDetails.vendorLoginCodeExpired && (
            <Alert severity="error" sx={{ mb: 3, mx: 2 }}>
              Your email service provider login has expired. Please close the
              wizard and try again.
            </Alert>
          )}
          {renderStepContent(state.activeStep)}
        </DialogContent>
        <DialogActions
          sx={{
            borderTop: "1px solid #e0e0e0",
            backgroundColor: grey[100],
            padding: "16px 24px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row-reverse",
          }}
        >
          <Box>
            <Button
              disabled={state.activeStep === 0 || state.success}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={!isNextButtonEnabled()}
            >
              {state.activeStep === 3 ? "Finish" : "Next"}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Wizard;
