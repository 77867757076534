import { Interpolation, PaletteOptions, Theme } from "@mui/material";
import {
  SpacingOptions,
  ShapeOptions,
  BreakpointsOptions,
  Direction,
  alpha,
} from "@mui/system";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { CSSProperties } from "react";

// --- CONSTANTS REFERENCED IN THEME FILES ---
type DefinedCSSPropertiesColorBloxColors = Exclude<
  CSSProperties["color"],
  undefined
>;

type BloxColorKeys =
  | "white"
  | "offwhite"
  | "darkgray"
  | "gray"
  | "lightgray"
  | "black"
  | "offblack"
  | "red"
  | "orange"
  | "yellow"
  | "green"
  | "blue"
  | "iceblue"
  | "logoblue"
  | "indigo"
  | "violet";

export type BloxColorsType = {
  [key in BloxColorKeys]: DefinedCSSPropertiesColorBloxColors;
};

type BloxIconKeys =
  | "article"
  | "image"
  | "collection"
  | "video"
  | "alink"
  | "table"
  | "file"
  | "pdf"
  | "poll"
  | "iframe"
  | "audio"
  | "batchUpload"
  | "html"
  | "metadata"
  | "form"
  | "import";

export type BloxIconColorsType = {
  [key in BloxIconKeys]: DefinedCSSPropertiesColorBloxColors;
};

export type BloxValuesType = {
  alphaCoefficient: number;
  appBarHeight: number;
};

// Common values we can reference in one place via theme.blox.values.*
export const bloxValues: BloxValuesType = {
  alphaCoefficient: 0.8, // Used to darken colors for dark mode.
  appBarHeight: 64,
};

// Blox Colors - these direct values apply if accessing via theme.blox.colors.*
// Dark mode uses the same values, but with a slight darken function applied to them.
export const bloxColors: BloxColorsType = {
  white: "#FFFFFF",
  offwhite: "#FEFFFF",
  darkgray: "#4F5B79",
  gray: "#999999",
  lightgray: "#D9D9D9",
  black: "#000000",
  offblack: "#121212",
  red: "#CD211F",
  orange: "#DD6022",
  yellow: "#E5D32D",
  green: "#38B349",
  blue: "#0047BB",
  iceblue: "#D2E5F2",
  logoblue: "#00A3E1",
  indigo: "#4938B3",
  violet: "#B338A2",
};

// Place any global styles here that can't be added to the theme overrides.
// e.g. to customize the browser's scrollbar, selection, html elements, etc.
// can think of this as the classic index.css file for html pages.
export const globalStyles: Interpolation<Theme> = {
  "::selection": {
    background: bloxColors.logoblue,
  },
  "::-moz-selection": {
    background: bloxColors.logoblue,
  },
  "html, body": {
    letterSpacing: "normal",
    lineHeight: "normal",
    overflow: "hidden",
    margin: 0,
  },
};

// Blox Icon Colors - these direct values apply if accessing via theme.blox.iconColors.*
// Dark mode uses the same values, but with a slight darken function applied to them.
export const bloxIconColors: BloxIconColorsType = {
  article: "#1BB934",
  image: "#1778BC",
  collection: "#1778BC",
  video: "#CB3300",
  alink: "#1A91EB",
  table: "#288F38",
  file: "#FFA800",
  pdf: "#DC2929",
  poll: "#32D9BB",
  iframe: "#CC2D79",
  audio: "#8865C0",
  batchUpload: "#000000",
  html: "#000000",
  metadata: "#000000",
  form: "#000000",
  import: "#000000",
};

// A set of colors for use in random assignment. Currently used to provide user collab colors.
export const userColors = [
  "#958DF1",
  "#F98181",
  "#FBBC88",
  "#FAF594",
  "#70CFF8",
  "#94FADB",
  "#B9F18D",
];

// --- BASE THEME CONFIG ---
export const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
  unit: "px",
  step: 5,
};

export const direction: Direction = "ltr";

export const spacing: SpacingOptions = 8;

export const shape: ShapeOptions = {
  borderRadius: 4,
};

export const typography: TypographyOptions = {
  htmlFontSize: 16, // base font size for the html document
  fontSize: 14, // default font size for components
  fontFamily:
    'Roboto,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  allVariants: {
    lineHeight: "normal",
    letterSpacing: "normal",
  },
};

// --- SPECIFIC LIGHT/DARK BASE THEME CONFIG / OVERRIDES ---
export const lightPalette: PaletteOptions = {
  mode: "light",
  contrastThreshold: 4,
  tonalOffset: 0.2,
  common: {
    black: bloxColors.black,
    white: bloxColors.white,
  },
  background: {
    default: bloxColors.white,
    paper: bloxColors.offwhite,
  },
  text: {
    primary: bloxColors.black,
  },
  divider: bloxColors.lightgray,
  primary: {
    main: bloxColors.blue,
  },
  secondary: {
    main: bloxColors.green,
  },
  error: {
    main: bloxColors.red,
  },
  warning: {
    main: bloxColors.orange,
    contrastText: bloxColors.white,
  },
  info: {
    main: bloxColors.gray,
    contrastText: bloxColors.white,
  },
  success: {
    main: bloxColors.green,
    contrastText: bloxColors.white,
  },
};

export const darkPalette: PaletteOptions = {
  mode: "dark",
  contrastThreshold: 4,
  tonalOffset: 0.2,
  common: {
    black: bloxColors.black,
    white: bloxColors.white,
  },
  background: {
    default: bloxColors.black,
    paper: bloxColors.offblack,
  },
  text: {
    primary: bloxColors.white,
  },
  divider: bloxColors.darkgray,
  primary: {
    main: alpha(bloxColors.blue, bloxValues.alphaCoefficient),
  },
  secondary: {
    main: alpha(bloxColors.green, bloxValues.alphaCoefficient),
  },
  error: {
    main: alpha(bloxColors.red, bloxValues.alphaCoefficient),
  },
  warning: {
    main: alpha(bloxColors.orange, bloxValues.alphaCoefficient),
  },
  info: {
    main: alpha(bloxColors.gray, bloxValues.alphaCoefficient),
  },
  success: {
    main: alpha(bloxColors.green, bloxValues.alphaCoefficient),
  },
};
