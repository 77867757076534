interface AuthorizationURLParams {
  redirectUri: string;
  clientId: string;
  scope: string;
  state: string;
}

const getAuthorizationURL = ({
  redirectUri,
  clientId,
  scope,
  state,
}: AuthorizationURLParams): string => {
  const authUrl = new URL(
    "https://authz.constantcontact.com/oauth2/default/v1/authorize"
  );
  authUrl.searchParams.append("client_id", clientId);
  authUrl.searchParams.append("redirect_uri", redirectUri);
  authUrl.searchParams.append("scope", scope);
  authUrl.searchParams.append("response_type", "code");
  authUrl.searchParams.append("state", state);

  return authUrl.toString();
};

const stateGenerator = (): string => Math.random().toString(36).substring(7);

interface OAuth2Config {
  baseUrl: string;
  clientId: string;
}

const ConstantContactProvider = (
  config: OAuth2Config,
  redirectUri: string = `${window.location.origin}/constantcontact-callback`
) => {
  const storeState = (state: string): void => {
    window.sessionStorage.setItem("oauth2_state", state);
  };

  const validateState = (state: string | null): boolean => {
    return window.sessionStorage.getItem("oauth2_state") === state;
  };

  if (!config.baseUrl || !config.clientId) {
    throw new Error("OAuth2 configuration is incomplete.");
  }

  const handleCallback = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.has("code") && urlParams.has("state")) {
        const state = urlParams.get("state");
        if (!validateState(state)) {
          reject(new Error("Invalid state parameter."));
        }

        const code = urlParams.get("code");
        if (code) {
          resolve(code);
        } else {
          reject(new Error("Code not found in callback URL."));
        }
      } else {
        reject(new Error("Required parameters not found in callback URL."));
      }
    });
  };

  const login = (): void => {
    const urlParams = new URLSearchParams(window.location.search);
    const state = stateGenerator();
    storeState(state);

    const authUrl = getAuthorizationURL({
      redirectUri,
      clientId: config.clientId,
      scope:
        "contact_data campaign_data account_read account_update offline_access",
      state,
    });

    if (!urlParams.has("code") && !urlParams.has("state")) {
      window.location.href = authUrl;
    }
  };

  return {
    login,
    handleCallback,
  };
};

export default ConstantContactProvider;
