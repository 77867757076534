import { useState, Suspense } from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  Box,
  Card,
  IconButton,
  Tooltip,
  Button,
  TextField,
  Select,
  MenuItem,
  Collapse,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { TIMEZONES } from "../../../constants";
import { useQueryClient, useMutation } from "react-query";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { useEmailReach } from "../../../providers/contexts/EmailReachContext";
import { useEmailReachDataProvider } from "../../../providers/contexts/EmailReachDataProvidersContext";
import { useClipboard } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import DeleteModal from "./DeleteDialog/DeleteDialog";

const AccountHeader = () => (
  <Box display={"flex"} alignItems={"center"} marginBottom="10px">
    <PersonRoundedIcon sx={{ paddingRight: "10px" }} />
    <Typography variant="h6">Account Settings</Typography>
  </Box>
);

const CreateAccountCard = ({ onNavigate }: any) => (
  <Card
    itemType="button"
    sx={{
      marginBottom: "20px",
      padding: "15px",
      cursor: "pointer",
      "&:hover": { backgroundColor: "rgba(0,0,0,0.04)" },
    }}
    onClick={onNavigate}
  >
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      width={"100%"}
    >
      <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
        <Typography fontSize={"1.1rem"}>Create new account</Typography>
        <Typography variant="subtitle1">
          Connect to a new email service provider
        </Typography>
      </Box>
      <ArrowForwardIosRoundedIcon
        sx={{ color: "GrayText", fontSize: "1rem" }}
      />
    </Box>
  </Card>
);

const UpdateAccountCard = () => {
  const { activeAccount, setActiveAccount } = useEmailReach();
  const [accountName, setAccountName] = useState(
    activeAccount?.account_identifier || ""
  );
  const [selectedTimezone, setSelectedTimezone] = useState(
    activeAccount?.timezone || ""
  );
  const [expanded, setExpanded] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { dataProvider } = useEmailReachDataProvider();
  const queryClient = useQueryClient();
  const theme = useTheme();

  const updateAccountMutation = useMutation(
    async ({
      account_identifier,
      timezone,
    }: {
      account_identifier: string;
      timezone: string;
    }) => {
      const response = await dataProvider.updateAccount({
        account_identifier,
        timezone,
      });
      return response.data;
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries("accounts");
        setExpanded(false);
        setSuccessMessage("Account updated successfully!");
        setErrorMessage("");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);

        if (activeAccount) {
          setActiveAccount({
            ...activeAccount,
            account_identifier: variables.account_identifier,
            timezone: variables.timezone,
          });
        }
      },
      onError: () => {
        setErrorMessage("Failed to update account. Please try again.");
        setSuccessMessage("");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      },
    }
  );

  const handleAccountNameChange = (event: any) => {
    setAccountName(event.target.value);
  };

  const handleTimezoneChange = (event: any) => {
    setSelectedTimezone(event.target.value);
  };

  const handleSubmit = () => {
    updateAccountMutation.mutate({
      account_identifier: accountName,
      timezone: selectedTimezone,
    });
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const isDefaultAccount =
    accountName === activeAccount?.account_identifier &&
    selectedTimezone === activeAccount?.timezone;

  return (
    <Card
      itemType="button"
      sx={{
        marginBottom: "20px",
        padding: "15px",
        cursor: "pointer",
        "&:hover": { backgroundColor: "rgba(0,0,0,0.04)" },
      }}
      onClick={() => setExpanded(!expanded)}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
        >
          <Typography fontSize={"1.1rem"}>Update Account</Typography>
          <Typography variant="subtitle1">
            Modify account name and time zone
          </Typography>
        </Box>
        <ArrowForwardIosRoundedIcon
          sx={{
            color: "GrayText",
            fontSize: "1rem",
            transform: expanded ? "rotate(90deg)" : "rotate(0deg)",
            transition: "transform 0.2s",
          }}
        />
      </Box>
      <Collapse in={expanded}>
        <Box mt={2} onClick={handleMenuClick}>
          <TextField
            label="Account Name"
            value={accountName}
            onChange={handleAccountNameChange}
            fullWidth
            size="small"
            margin="normal"
          />
          <Select
            size="small"
            value={selectedTimezone}
            onChange={handleTimezoneChange}
            fullWidth
          >
            {TIMEZONES.map((timezone) => (
              <MenuItem key={timezone.value} value={timezone.value}>
                {timezone.label}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              backgroundColor: theme.blox.colors.green,
              "&:hover": {
                backgroundColor: theme.blox.colors.green,
              },
              marginTop: "10px",
            }}
            disabled={
              isDefaultAccount ||
              !accountName ||
              !selectedTimezone ||
              updateAccountMutation.isLoading
            }
          >
            {updateAccountMutation.isLoading ? "Updating..." : "Update"}
          </Button>
        </Box>
      </Collapse>
      <Collapse in={showAlert}>
        <Box mt={1}>
          <Alert severity={successMessage ? "success" : "error"}>
            <Typography
              variant="subtitle2"
              color={successMessage ? "success.main" : "error.main"}
            >
              {successMessage || errorMessage}
            </Typography>
          </Alert>
        </Box>
      </Collapse>
    </Card>
  );
};

const CurrentAccountAPIKey = ({ publicKey }: any) => {
  const { copied, copy } = useClipboard();

  return (
    <Card sx={{ padding: "15px", marginBottom: "20px" }}>
      <Box>
        <Typography fontSize={"1.1rem"}>Current account API key:</Typography>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography>{publicKey}</Typography>
        <Tooltip title={copied ? "Copied!" : "Copy"} placement="top">
          <IconButton
            onClick={() => copy(publicKey)}
            sx={{
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                borderRadius: "50%",
              },
            }}
          >
            {copied ? <CheckCircleRoundedIcon /> : <ContentCopyRoundedIcon />}
          </IconButton>
        </Tooltip>
      </Box>
    </Card>
  );
};

const DeleteAccountButton = ({ onClick }: any) => (
  <Box display="flex" justifyContent="center">
    <Button variant="contained" color="error" fullWidth onClick={onClick}>
      <Typography variant="subtitle2">Delete current account</Typography>
    </Button>
  </Box>
);

const SettingsPanel = ({ isSettingsOpen, setIsSettingsOpen }: any) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { activeAccount } = useEmailReach();
  const publicKey = activeAccount?.public_key;
  const navigate = useNavigate();

  const handleCreateAccountNavigation = () => {
    navigate("/account/create");
    setIsSettingsOpen(false);
  };

  return (
    <Box>
      <Dialog open={isSettingsOpen} onClose={() => setIsSettingsOpen(false)}>
        <CloseRoundedIcon
          type="button"
          sx={{
            position: "absolute",
            top: "20px",
            right: "20px",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0.04)",
              borderRadius: "5px",
            },
          }}
          onClick={() => setIsSettingsOpen(false)}
        />
        <DialogContent sx={{ width: "500px" }}>
          <AccountHeader />
          <CreateAccountCard onNavigate={handleCreateAccountNavigation} />
          <UpdateAccountCard />
          <CurrentAccountAPIKey publicKey={publicKey} />
          <DeleteAccountButton onClick={() => setIsDeleteModalOpen(true)} />
          <Suspense>
            <DeleteModal
              isDeleteModalOpen={isDeleteModalOpen}
              setIsDeleteModalOpen={setIsDeleteModalOpen}
              setIsSettingsOpen={setIsSettingsOpen}
            />
          </Suspense>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default SettingsPanel;
