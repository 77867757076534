import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import {
  Box,
  Typography,
  Card,
  Fade,
  Button,
  Alert,
  Link,
  Collapse,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import Logo from "../Logo/Logo";
import ErrorIcon from "@mui/icons-material/Error";

interface BloxLoadingSplashProps {
  message?: string;
  secondaryMessage?: string;
  isLoading: boolean;
  isLoggingOut?: boolean;
  error?: string | null;
  children: React.ReactNode;
}

const BloxLoadingSplash = React.memo(
  ({
    message,
    isLoading,
    isLoggingOut,
    children,
    secondaryMessage,
    error,
  }: BloxLoadingSplashProps) => {
    const { logout } = useAuth0();
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          position: "relative",
          backgroundColor: error ? "#00263a" : "background.default",
          transition: "background-color 0.7s ease-out",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "all 0.2s ease-out",
            transform:
              isLoading || error || isLoggingOut ? "scale(1)" : "scale(1.3)",
            opacity: isLoading || error || isLoggingOut ? 1 : 0,
            pointerEvents: isLoading || error || isLoggingOut ? "auto" : "none",
            zIndex: isLoading || error || isLoggingOut ? 1 : -1,
          }}
        >
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "400px",
              padding: "2rem",
              borderRadius: "1rem",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Logo size={200} />
            <Box sx={{ minHeight: "2.5rem", mt: 2 }}>
              {error ? (
                <Fade in={true}>
                  <Alert
                    severity="error"
                    icon={<ErrorIcon fontSize="inherit" />}
                    sx={{ mb: 2 }}
                  >
                    {error}
                  </Alert>
                </Fade>
              ) : message ? (
                <Fade in={true}>
                  <Typography
                    sx={{
                      mb: 2,
                      lineHeight: 1,
                      fontSize: "1.25rem",
                      textAlign: "center",
                    }}
                  >
                    {message}
                  </Typography>
                </Fade>
              ) : null}
              <Collapse in={!!secondaryMessage}>
                <Typography
                  sx={{
                    mb: 2,
                    lineHeight: 1,
                    fontSize: "1rem",
                    textAlign: "center",
                    color: "text.secondary",
                  }}
                >
                  {secondaryMessage}
                </Typography>
              </Collapse>
            </Box>
            {!error ? (
              <Fade in={isLoading || isLoggingOut}>
                <LinearProgress
                  variant="indeterminate"
                  sx={{ height: "6px", width: "100%", borderRadius: "4px" }}
                />
              </Fade>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Button variant="contained" onClick={() => logout()}>
                  Log Out
                </Button>
                <Link
                  href="https://www.bloxdigital.com/support/"
                  target="_blank"
                >
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    sx={{ mt: 2 }}
                  >
                    Need help? Contact support
                  </Typography>
                </Link>
              </Box>
            )}
          </Card>
        </Box>
        <Box
          sx={{
            opacity: isLoading || error ? 0 : 1,
            transition: "opacity 0.2s ease-out 0.2s",
            pointerEvents: isLoading || error ? "none" : "auto",
            width: "100%",
            height: "100%",
          }}
        >
          {children}
        </Box>
      </Box>
    );
  }
);

export default BloxLoadingSplash;
