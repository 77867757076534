import { Box } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import lightLogo from "../../assets/images/logo.light.svg";
import darkLogo from "../../assets/images/logo.dark.svg";

const Logo = ({ size, dark }: any) => {
  const theme = useTheme();

  return (
    <Box display="grid" alignItems="center">
      <Box display="flex">
        <Box
          alt="BLOX: Email Reach"
          src={theme.palette.mode === "dark" || dark ? darkLogo : lightLogo}
          component="img"
          height={size / 1.5}
          width={size}
        />
      </Box>
    </Box>
  );
};

export default Logo;
