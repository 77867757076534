import React, { useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Card,
  Button,
  Alert,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useMutation } from "react-query";
import { useSnackbar } from "../../../../../providers/contexts/SnackbarContext";
import { useEmailReachDataProvider } from "../../../../../providers/contexts/EmailReachDataProvidersContext";
import { useEmailReach } from "../../../../../providers/contexts/EmailReachContext";
import { useTheme } from "@mui/material/styles";

interface AccountCreationData {
  accountIdentifier: string;
  vendorDetails: {
    vendorId: string;
    vendorName: string;
    vendorLoginCode: string;
    vendorLoginCodeExpired: boolean;
  };
  timezone: string;
  timezoneLabel: string;
}

interface AccountReviewProps {
  accountDetails: AccountCreationData;
  setState: (updatedState: any) => void;
}

const AccountReview: React.FC<AccountReviewProps> = ({
  accountDetails,
  setState,
}) => {
  const [creatingAccount, setCreatingAccount] = React.useState(false);
  const [accountCreationSuccess, setAccountCreationSuccess] = useState(false);

  const { dataProvider } = useEmailReachDataProvider();
  const { openSnackbar } = useSnackbar();
  const theme = useTheme();
  const { setNewAccountTemp } = useEmailReach();

  const accountBody: any = {
    vendor_uuid: accountDetails.vendorDetails.vendorId,
    account_identifier: accountDetails.accountIdentifier,
    timezone: accountDetails.timezone,
    vendor_auth_data: {
      oauth_code: accountDetails.vendorDetails.vendorLoginCode,
      redirect_uri: `${process.env.REACT_APP_BASE_URL}/constantcontact-callback`,
    },
  };

  const createAccount = async (accountBody: any) => {
    const response = await dataProvider.create("accounts", {
      data: accountBody,
    });
    return response;
  };

  const mutation = useMutation(createAccount, {
    onSuccess: (response) => {
      setState((prevState: any) => ({
        ...prevState,
        success: true,
        accountDetails: {
          ...prevState.accountDetails,
        },
      }));
      setAccountCreationSuccess(true);
      setCreatingAccount(false);
      openSnackbar("Account created successfully", "success");
      setNewAccountTemp(response.data.details);
    },
    onError: (error) => {
      setCreatingAccount(false);
      openSnackbar("Error creating account", "error");
      console.error("Error creating account", error);
    },
  });

  const handleCreateAccount = () => {
    setCreatingAccount(true);
    mutation.mutate(accountBody);
  };

  return (
    <Box
      sx={{
        margin: "auto",
        width: "auto",
        p: 2,
      }}
    >
      {accountCreationSuccess ? (
        <>
          <Typography
            variant="h6"
            sx={{ mb: 2, fontWeight: 600, textAlign: "center" }}
          >
            Account Created Successfully!
          </Typography>
          <Alert severity="success" sx={{ mb: 3 }}>
            Your account has been created. Here's a summary of your details.
          </Alert>
          <SuccessSummary accountDetails={accountDetails} />
        </>
      ) : (
        <>
          <Typography
            variant="h6"
            sx={{ mb: 2, fontWeight: 600, textAlign: "center" }}
          >
            Review and Confirm Your Details
          </Typography>

          <Alert severity="info" sx={{ mb: 3 }}>
            Please review your account details before creating your account.
          </Alert>

          <ReviewCard title="Account Information">
            <Detail
              label="Account Name"
              value={accountDetails.accountIdentifier}
            />
            <Detail
              label="Email Service Provider"
              value={accountDetails.vendorDetails.vendorName}
            />
            <Detail label="Timezone" value={accountDetails.timezoneLabel} />
          </ReviewCard>

          <Box display="flex" justifyContent="center">
            <Button
              variant="contained"
              onClick={handleCreateAccount}
              disabled={
                creatingAccount ||
                accountDetails.vendorDetails.vendorLoginCodeExpired
              }
              fullWidth
              sx={{
                py: 1.5,
                textTransform: "none",
                fontSize: "1.1rem",
                boxShadow: 1,
                backgroundColor: theme.blox.colors.green,
                "&:hover": {
                  backgroundColor: theme.blox.colors.green,
                },
              }}
            >
              {creatingAccount ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <Typography fontWeight="600">Create Account</Typography>
              )}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

const SuccessSummary = ({ accountDetails }: any) => (
  <Card sx={{ p: 2, mb: 2, width: "auto" }}>
    <DetailWithSuccess
      label="Account Name"
      value={accountDetails.accountIdentifier}
    />
    <DetailWithSuccess
      label="Email Service Provider"
      value={accountDetails.vendorDetails.vendorName}
    />
    <DetailWithSuccess label="Timezone" value={accountDetails.timezoneLabel} />
  </Card>
);

const DetailWithSuccess = ({ label, value }: any) => (
  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
    <CheckCircleIcon color="success" sx={{ mr: 1 }} />
    <Typography variant="body2" sx={{ fontWeight: "600" }}>
      {label}:
    </Typography>
    <Typography variant="body2" sx={{ ml: 1 }}>
      {value}
    </Typography>
  </Box>
);

const ReviewCard = ({
  title,
  children,
  sx,
}: {
  title: string;
  children: React.ReactNode;
  sx?: any;
}) => (
  <Box sx={{ width: "100%", mb: 4 }}>
    <Card
      sx={{
        px: 4,
        py: 2,
        boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
        mb: 2,
        ...sx,
      }}
    >
      <Typography
        variant="body1"
        sx={{
          fontWeight: "600",
          mb: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{ display: "grid", gridTemplateColumns: "repeat(1, 1fr)", gap: 2 }}
      >
        {children}
      </Box>
    </Card>
  </Box>
);

const Detail = ({
  label,
  value,
  onEdit,
  disabled,
}: {
  label: string;
  value: string | number | null | undefined;
  disabled?: boolean;
  onEdit?: () => void;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        position: "relative",
      }}
    >
      <Typography variant="body2" sx={{ mr: 1, fontWeight: "600" }}>
        {label}:
      </Typography>

      <Typography
        variant="body2"
        color={disabled ? "text.secondary" : ""}
        sx={{ fontWeight: "normal" }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default AccountReview;
