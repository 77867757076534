import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  InputAdornment,
  Card,
  CardContent,
  Popper,
  Grow,
  MenuItem,
  ListItemIcon,
  Divider,
  Skeleton,
  ClickAwayListener,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import { useEmailReach } from "../../../providers/contexts/EmailReachContext";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import LanguageIcon from "@mui/icons-material/Language";

interface Account {
  account_uuid: string;
  account_identifier: string;
  public_key: string;
  vendor_name: string;
  vendor_uuid: string;
}

const AccountSelector = () => {
  const context = useEmailReach();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  if (!context) {
    throw new Error(
      "AccountSelector must be used within an EmailReachContext provider"
    );
  }

  const { accounts, activeAccount, setActiveAccount } = context;

  const handleAccountClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsOpen(!isOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleAccountChange = (account: Account) => {
    setActiveAccount(account);
    const queryCache = queryClient.getQueryCache();
    const allQueries = queryCache.findAll();
    allQueries.forEach((query) => {
      if (query.queryKey !== "accounts") {
        queryClient.removeQueries(query.queryKey);
      }
    });
    setIsOpen(false);
    setSearchQuery("");
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleClickAway = () => {
    setIsOpen(false);
  };

  const filterAccounts = (query: string) => {
    return accounts.filter((account) =>
      account.account_identifier.toLowerCase().includes(query.toLowerCase())
    );
  };

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      if (!activeAccount) {
        setActiveAccount(accounts[0]);
      } else {
        const currentActiveAccount = accounts.find(
          (account) => account.account_uuid === activeAccount.account_uuid
        );
        if (currentActiveAccount) {
          setActiveAccount(currentActiveAccount);
        } else {
          setActiveAccount(accounts[0]);
        }
      }
    }
  }, [accounts, activeAccount, setActiveAccount]);

  if (
    !accounts ||
    accounts.length === 0 ||
    window.location.pathname === "/create-account"
  ) {
    return (
      <Box display="flex" alignItems="center" marginRight={2}>
        <LanguageIcon sx={{ mr: 1, color: "text.secondary" }} />
        <Typography color="text.secondary">No accounts available</Typography>
      </Box>
    );
  }

  const filteredAccounts = filterAccounts(searchQuery);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <Box
          display="flex"
          onClick={handleAccountClick}
          borderRadius={2}
          boxShadow={
            isOpen
              ? "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
              : 0
          }
          py={0.5}
          pl={2}
          sx={{
            cursor: "pointer",
            transition:
              "background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out",
            userSelect: "none",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.02)",
            },
          }}
        >
          <Box display="flex" gap={1} alignItems="center">
            <LanguageIcon sx={{ color: "text.primary" }} />
            <Typography color="text.primary" fontWeight="bold">
              Account:
            </Typography>
            <Typography color="text.primary">
              {activeAccount ? (
                activeAccount.account_identifier
              ) : (
                <Skeleton variant="text" width={120} />
              )}
            </Typography>
          </Box>
          <IconButton
            size="small"
            sx={
              {
                // mx: 2,
              }
            }
          >
            <ExpandMoreIcon
              sx={{
                fontSize: "1.1rem",
                transform: isOpen ? "rotate(180deg)" : undefined,
                transition: "transform 0.2s ease-in-out",
              }}
            />
          </IconButton>
        </Box>
        <Popper
          open={isOpen}
          anchorEl={anchorEl}
          placement="bottom-start"
          transition
          sx={{ zIndex: "9999" }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Card
                sx={{
                  mt: 1,
                  borderRadius: "8px",
                  boxShadow: 5,
                  width: 400,
                }}
              >
                <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
                  <Box display="flex" alignItems="center" mb={1} p={2} pb={0}>
                    <TextField
                      placeholder="Search accounts"
                      variant="outlined"
                      size="small"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      autoFocus
                      aria-label="Search accounts"
                    />
                  </Box>
                  <List
                    dense
                    sx={{
                      maxHeight: "300px",
                      overflowY: "auto",
                    }}
                  >
                    {filteredAccounts.length === 0 ? (
                      <ListItemButton>
                        <ListItemText
                          primary={
                            <Typography color="text.secondary">
                              No accounts found
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    ) : (
                      filteredAccounts.map((account) => (
                        <AccountListItem
                          key={account.account_uuid}
                          account={account}
                          activeAccount={activeAccount}
                          onAccountChange={handleAccountChange}
                        />
                      ))
                    )}
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        setIsOpen(false);
                        navigate("/account/create");
                      }}
                      sx={{ mt: 1 }}
                    >
                      <ListItemIcon>
                        <AddIcon />
                      </ListItemIcon>
                      <ListItemText primary="Create New Account" />
                    </MenuItem>
                  </List>
                </CardContent>
              </Card>
            </Grow>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

const AccountListItem = ({
  account,
  activeAccount,
  onAccountChange,
}: {
  account: Account;
  activeAccount: Account | null;
  onAccountChange: (account: Account) => void;
}) => {
  return (
    <ListItemButton
      onClick={() => onAccountChange(account)}
      aria-label={`Select ${account.account_identifier}`}
      sx={{
        backgroundColor:
          account === activeAccount ? "rgba(0, 0, 0, 0.05)" : "transparent",
      }}
    >
      <ListItemIcon>{account === activeAccount && <CheckIcon />}</ListItemIcon>
      <ListItemText
        primary={
          <Typography
            style={{
              fontWeight: account === activeAccount ? "bold" : "normal",
            }}
          >
            {account.account_identifier}
          </Typography>
        }
      />
    </ListItemButton>
  );
};

export default AccountSelector;
