import React from "react";
import { Snackbar, Alert } from "@mui/material";

export type SnackbarSeverity =
  | "success"
  | "info"
  | "warning"
  | "error"
  | undefined;

type SnackbarAlertProps = {
  snackbarOpen: boolean;
  closeSnackbar: () => void;
  snackbarMessage: string;
  snackbarSeverity: SnackbarSeverity;
};

const SnackbarAlert: React.FC<SnackbarAlertProps> = ({
  snackbarOpen,
  closeSnackbar,
  snackbarMessage,
  snackbarSeverity,
}) => {
  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={closeSnackbar}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      sx={{
        ".MuiPaper-root": {
          borderRadius: "8px",
          backgroundColor: "rgba(255,255,255,0.95)",
          boxShadow: "0 4px 12px rgba(0,0,0,0.2)",
          minWidth: "280px",
          maxWidth: "90%",
          margin: "0.5em",
          border: "1px solid #e0e0e0",
          ".MuiAlert-icon": {
            fontSize: "1.5em",
          },
          ".MuiAlert-message": {
            color: "#323232",
            fontSize: "0.95rem",
            fontWeight: "500",
          },
          ".MuiAlert-action": {
            marginRight: "0",
          },
        },
      }}
    >
      <Alert
        onClose={closeSnackbar}
        severity={snackbarSeverity}
        sx={{ width: "100%" }}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarAlert;
