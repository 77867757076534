import React, { useEffect } from "react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

const AuthProviderWithHistory: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    const checkAuthentication = async () => {
      if (!isAuthenticated && !isLoading) {
        try {
          await getAccessTokenSilently();
        } catch (e) {
          loginWithRedirect();
        }
      }
    };

    checkAuthentication();
  }, [isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently]);

  return <>{children}</>;
};

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const redirectUri = window.location.origin + "/auth-callback";

  const onRedirectCallback = (appState: any) => {
    window.history.replaceState(
      {},
      document.title,
      appState?.returnTo || window.location.pathname
    );
  };

  if (!(domain && clientId && audience)) {
    console.error("Auth0 configuration error");
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: audience,
        scope: "openid profile email offline_access",
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      <AuthProviderWithHistory>{children}</AuthProviderWithHistory>
    </Auth0Provider>
  );
};

export default AuthProvider;
