import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from "react";
import { useQuery, useMutation } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Account,
  EmailReachContextState,
  EmailReachProviderProps,
} from "../types/emailReachContext";

// Define default context values for managing email reach states.
const defaultContextValue: EmailReachContextState = {
  isReady: false,
  isLoading: false,
  accountsError: null,
  errorMessage: null,
  accounts: [],
  activeAccount: null,
  newAccountTemp: null,
  setActiveAccount: () => {},
  setNewAccountTemp: () => {},
  refetch: () => {},
  showReAuthModal: false,
  setShowReAuthModal: () => {},
  isReAuthLoading: false,
  isReAuthSuccess: false,
  isReAuthError: false,
  resetReAuth: () => {},
  initialLoad: true,
  isReAuthComplete: false,
  showNoAccounts: false,
};

// Create a context for EmailReach to manage its state across the app.
export const EmailReachContext =
  createContext<EmailReachContextState>(defaultContextValue);

// API endpoint from environment variables.
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const ACTIVE_ACCOUNT_KEY = "blox_emailReach_activeAccount";

// Custom hook to use EmailReach context.
export const useEmailReach = () => {
  const context = useContext(EmailReachContext);
  if (context === undefined) {
    throw new Error("useEmailReach must be used within a EmailReachProvider");
  }
  return context;
};

// Provider component to manage and provide EmailReach context.
export const EmailReachProvider: React.FC<EmailReachProviderProps> = ({
  children,
}) => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [activeAccount, setActiveAccount] = useState<Account | null>(() => {
    const savedAccount = localStorage.getItem(ACTIVE_ACCOUNT_KEY);
    return savedAccount ? JSON.parse(savedAccount) : null;
  });
  const [initialLoad, setInitialLoad] = useState(true);
  const [newAccountTemp, setNewAccountTemp] = useState<Account | null>();
  const [showNoAccounts, setShowNoAccounts] = useState(false);
  const [showReAuthModal, setShowReAuthModal] = useState(false);
  const [isReAuthComplete, setIsReAuthComplete] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  // Function to determine retry logic on query failure.
  const retryFunction = (failureCount: number, error: any) => {
    if (error?.status === 401) {
      return false;
    }
    return failureCount < 3;
  };

  const fetchAccounts = async () => {
    try {
      const tokenResponse = await getAccessTokenSilently();
      if (!tokenResponse) {
        throw new Error(
          "Failed to get access token. Please try logging in again."
        );
      }
      const token = tokenResponse;
      const response = await fetch(`${API_ENDPOINT}/accounts`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.message || "Failed to fetch accounts.";
        const errorStatus = response.status;
        throw new Error(`${errorMessage} (Status: ${errorStatus})`);
      }
      return response.json();
    } catch (error) {
      if (error instanceof Error) {
        // Log the error for debugging purposes
        console.error("Error fetching accounts:", error);
        // Extract the error message from the error object
        const errorMessage = `An unexpected error occurred while fetching accounts: ${error.message}`;
        // Throw the error with the extracted message
        throw new Error(errorMessage);
      } else {
        // Log the unexpected error for debugging purposes
        console.error("Unexpected error fetching accounts:", error);
        // Throw a generic error for unexpected cases
        throw new Error(
          "An unexpected error occurred while fetching accounts."
        );
      }
    }
  };

  // Query to fetch accounts and manage loading and error states.
  const {
    data: accountsData,
    isLoading,
    error: accountsError,
    refetch,
  } = useQuery("accounts", fetchAccounts, {
    refetchOnWindowFocus: false,
    retry: retryFunction,
    enabled: isAuthenticated,
  });

  // Create an error message based on the accountsError
  const getErrorMessage = (error: unknown): string => {
    if (error instanceof Error) {
      return error.message;
    } else {
      return "An unexpected error occurred. Please try again.";
    }
  };

  const errorMessage = accountsError ? getErrorMessage(accountsError) : null;

  // Effect hook to manage no accounts state.
  useEffect(() => {
    if (accounts.length === 0 && !isLoading) {
      const timer = setTimeout(() => {
        setShowNoAccounts(true);
      }, 500);
      return () => clearTimeout(timer);
    } else {
      setShowNoAccounts(false);
    }
  }, [accounts, isLoading]);

  // Effect hook to set accounts and active account based on fetched data.
  useEffect(() => {
    if (
      accountsData &&
      accountsData.details &&
      accountsData.details.length > 0
    ) {
      setAccounts(accountsData.details);
      if (newAccountTemp) {
        const isAccountPresent = accountsData.details.some(
          (a: Account) => a.account_uuid === newAccountTemp.account_uuid
        );
        if (isAccountPresent) {
          setActiveAccount(newAccountTemp);
          setNewAccountTemp(null);
        }
      } else if (!activeAccount) {
        const savedAccount = JSON.parse(
          localStorage.getItem(ACTIVE_ACCOUNT_KEY) || "{}"
        );
        const validSavedAccount = accountsData.details.find(
          (a: Account) => a.account_uuid === savedAccount.account_uuid
        );
        setActiveAccount(validSavedAccount || accountsData.details[0]);
      }
    } else {
      setAccounts([]);
      setActiveAccount(null);
    }
  }, [accountsData, newAccountTemp, activeAccount]);

  // Effect hook to handle active account storage and initialization.
  useEffect(() => {
    if (!localStorage.getItem(ACTIVE_ACCOUNT_KEY)) {
      setActiveAccount(accounts[0]);
    }
  }, [accounts]);

  // Effect hook to update local storage with active account.
  useEffect(() => {
    if (activeAccount) {
      localStorage.setItem(ACTIVE_ACCOUNT_KEY, JSON.stringify(activeAccount));
    }
  }, [activeAccount]);

  // Effect hook to control initial load state.
  useEffect(() => {
    setInitialLoad(isLoading);
  }, [isLoading]);

  // Effect hook to handle re-authentication modal visibility.
  useEffect(() => {
    const showReAuth = () => {
      if (!showReAuthModal) {
        setShowReAuthModal(true);
      }
    };
    const hideReAuth = () => setShowReAuthModal(false);

    window.addEventListener("require-reauth", showReAuth);
    window.addEventListener("close-reauth", hideReAuth);

    return () => {
      window.removeEventListener("require-reauth", showReAuth);
      window.removeEventListener("close-reauth", hideReAuth);
    };
  }, [showReAuthModal]);

  // Effect hook to clean up re-authentication state.
  useEffect(() => {
    if (isReAuthComplete) {
      localStorage.removeItem("isReAuth");
      localStorage.removeItem("oauth2_code");
    }
  }, [isReAuthComplete]);

  // Mutation hook to handle re-authentication process.
  const {
    mutate: reAuth,
    isLoading: isReAuthLoading,
    isSuccess: isReAuthSuccess,
    isError: isReAuthError,
    reset: resetReAuth,
  } = useMutation(
    async () => {
      const oAuthCode = localStorage.getItem("oauth2_code");
      if (!oAuthCode) throw new Error("OAuth2 code not found");

      const token = await getAccessTokenSilently();
      const response = await fetch(`${API_ENDPOINT}/accounts/reauthenticate`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          account_uuid: activeAccount?.account_uuid,
          vendor_auth_data: {
            oauth_code: oAuthCode,
            redirect_uri: `${window.location.origin}/constantcontact-callback`,
          },
        }),
      });

      if (!response.ok) {
        throw new Error("Re-authentication request failed");
      }

      return response.json();
    },
    {
      onSuccess: () => {
        setIsReAuthComplete(true);
        setShowReAuthModal(true);
      },
      onError: (error) => {
        localStorage.removeItem("isReAuth");
        localStorage.removeItem("oauth2_code");
        console.error("Re-authentication error:", error);
        setIsReAuthComplete(true);
        setShowReAuthModal(true);
      },
    }
  );

  // Effect hook to handle OAuth callback and trigger re-authentication.
  useEffect(() => {
    const isOAuth2CallbackRoute =
      location.pathname === "/constantcontact-callback";

    if (
      isOAuth2CallbackRoute &&
      !isReAuthComplete &&
      localStorage.getItem("isReAuth") === "true"
    ) {
      const oAuthCode = new URLSearchParams(location.search).get("code");
      if (oAuthCode) {
        localStorage.setItem("oauth2_code", oAuthCode);
        window.dispatchEvent(new Event("oauth2-callback-complete"));
        navigate("/");
      } else {
        setIsReAuthComplete(true);
      }
    }
  }, [location, isReAuthComplete, navigate]);

  // Effect hook to initialize re-authentication flow.
  useEffect(() => {
    const isReAuthFlow = localStorage.getItem("isReAuth") === "true";
    const oAuthCode = localStorage.getItem("oauth2_code");

    if (isReAuthFlow && oAuthCode) {
      setIsReAuthComplete(false);
      reAuth();
    } else {
      setIsReAuthComplete(true);
    }
  }, [reAuth]);

  const handleReAuthentication = useCallback(() => {
    const oAuthCode = localStorage.getItem("oauth2_code");
    if (oAuthCode) {
      reAuth();
    } else {
      setIsReAuthComplete(true);
    }
  }, [reAuth]);

  // Effect hook to manage re-authentication process based on OAuth callback.
  useEffect(() => {
    window.addEventListener("oauth2-callback-complete", handleReAuthentication);

    return () => {
      window.removeEventListener(
        "oauth2-callback-complete",
        handleReAuthentication
      );
    };
  }, [reAuth, handleReAuthentication]);

  // Cleanup effect for re-authentication.
  useEffect(() => {
    return () => {
      resetReAuth();
    };
  }, [resetReAuth]);

  // Memoized context value to optimize performance.
  const contextValue = useMemo(
    () => ({
      isReady:
        !isLoading && !accountsError && accounts.length > 0 && isReAuthComplete,
      isLoading,
      accountsError,
      errorMessage,
      accounts,
      activeAccount,
      newAccountTemp,
      setActiveAccount,
      refetch,
      setNewAccountTemp,
      showReAuthModal,
      setShowReAuthModal,
      isReAuthLoading,
      isReAuthSuccess,
      isReAuthError,
      resetReAuth,
      initialLoad,
      isReAuthComplete,
      showNoAccounts,
    }),
    [
      isLoading,
      accountsError,
      errorMessage,
      accounts,
      activeAccount,
      refetch,
      newAccountTemp,
      showReAuthModal,
      isReAuthLoading,
      isReAuthSuccess,
      isReAuthError,
      resetReAuth,
      initialLoad,
      isReAuthComplete,
      showNoAccounts,
    ]
  );

  return (
    <EmailReachContext.Provider value={contextValue}>
      {children}
    </EmailReachContext.Provider>
  );
};
