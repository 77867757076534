import { PropsWithChildren } from "react";
import NavMenu from "./navigation/NavMenu/NavMenu";
import AppBarCustom from "./appBar/AppBar/AppBar";
import { Box, Paper } from "@mui/material";

const drawerWidth = 240;
const contentPadding = 1;

const Layout = ({ children }: PropsWithChildren) => {
  return (
    <Box sx={{ display: "flex", width: "100%", height: "100vh" }}>
      {/* AppBar */}
      <AppBarCustom />

      <Box sx={{ display: "flex", flexGrow: 1, mt: "64px", p: contentPadding }}>
        {/* Drawer */}
        <Paper
          elevation={1}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            overflow: "hidden",
            position: "relative",
            display: "flex",
            p: contentPadding,
          }}
        >
          <NavMenu />
        </Paper>

        {/* Main content */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            ml: contentPadding,
            display: "flex",
            p: 0,
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
