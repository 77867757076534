import { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Context Providers
import { useEmailReach } from "./providers/contexts/EmailReachContext";
import { SnackbarProvider } from "./providers/contexts/SnackbarContext";

// Layout
import Layout from "./layout/Layout";
import ReAuthDialog from "./components/ReAuthModal";

// Lazy Loaded Components
const OAuth2Callback = lazy(() => import("./components/utils/OAuth2Callback"));
const AuthCallback = lazy(() => import("./components/utils/AuthCallback"));
const AccountCreate = lazy(() => import("./screens/accounts/AccountCreate"));
const Lists = lazy(() => import("./screens/lists/Lists"));
const Contacts = lazy(() => import("./screens/contactList/ContactList"));
const Campaigns = lazy(() => import("./screens/campaigns/Campaigns"));
const SentMessages = lazy(() => import("./screens/sentMessages/SentMessages"));

const App = () => {
  const { isReady } = useEmailReach();
  if (!isReady) {
    return null;
  } else {
    return (
      <SnackbarProvider>
        <Layout>
          <Suspense>
            <ReAuthDialog />
            <AppRoutes />
          </Suspense>
        </Layout>
      </SnackbarProvider>
    );
  }
};

const AppRoutes = () => (
  <Routes>
    <Route path="/constantcontact-callback" element={<OAuth2Callback />} />
    <Route path="/auth-callback" element={<AuthCallback />} />
    <Route path="/account/create" element={<AccountCreate />} />
    <Route path="/contactlists/*" element={<Lists />} />
    <Route path="/contactlists/edit/:id" element={<Lists />} />
    <Route path="/contacts/*" element={<Contacts />} />
    <Route path="/contacts/edit/:id" element={<Contacts />} />
    <Route path="/campaigns/*" element={<Campaigns />} />
    <Route path="/campaigns/edit/:id" element={<Campaigns />} />
    <Route path="/sent-messages/*" element={<SentMessages />} />
    <Route path="*" element={<Navigate to="/contactlists" replace />} />
  </Routes>
);

export default App;
