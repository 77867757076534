import React, { useState, useEffect } from "react";
import { useEmailReach } from "./EmailReachContext";
import { EmailReachDataProvider } from "./EmailReachDataProvidersContext";
import { SnackbarProvider } from "./SnackbarContext";
import NoAccountsComponent from "../../components/NoAccountsComponent";
import { useAuth0 } from "@auth0/auth0-react";
import BloxLoadingSplash from "../../components/splashscreen/BloxLoadingSplash";

const EmailReachHandler = ({ children }: any) => {
  const {
    isLoading,
    errorMessage,
    accounts,
    isReAuthLoading,
    isReAuthComplete,
    initialLoad,
  } = useEmailReach();
  const { isAuthenticated, user, isLoading: isAuthLoading } = useAuth0();
  const [showNoAccounts, setShowNoAccounts] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [debouncedIsContextLoading, setDebouncedIsContextLoading] =
    useState(true);
  const isContextLoading =
    isAuthLoading || initialLoad || isReAuthLoading || !isReAuthComplete;

  // Effect hook to manage no accounts state.
  useEffect(() => {
    if (accounts.length === 0 && !isLoading && !errorMessage) {
      const timer = setTimeout(() => {
        setShowNoAccounts(true);
      }, 500);
      return () => clearTimeout(timer);
    } else {
      setShowNoAccounts(false);
    }
  }, [accounts, isLoading, errorMessage]);

  // Effect hook to manage logging out state.
  useEffect(() => {
    if (!isAuthenticated && !isAuthLoading) {
      setIsLoggingOut(true);
    } else if (isAuthenticated && !isAuthLoading) {
      setIsLoggingOut(false);
    }
  }, [isAuthenticated, isAuthLoading]);

  // Debounce effect for isContextLoading
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setDebouncedIsContextLoading(isContextLoading);
    }, 500); // Adjust the debounce delay as needed

    return () => clearTimeout(debounceTimer);
  }, [isContextLoading]);

  // Check if the error is a 401 unauthorized error
  const isUnauthorizedError =
    errorMessage && errorMessage.includes("(Status: 401)");

  if (showNoAccounts && !isContextLoading && !isLoggingOut) {
    return (
      <EmailReachDataProvider>
        <SnackbarProvider>
          <NoAccountsComponent />
        </SnackbarProvider>
      </EmailReachDataProvider>
    );
  }

  return (
    <BloxLoadingSplash
      isLoading={debouncedIsContextLoading}
      isLoggingOut={isLoggingOut}
      error={
        isUnauthorizedError
          ? "You don't have permission to access Email Reach. Please log in with the correct credentials or contact support for assistance."
          : errorMessage
      }
      message={user ? `Welcome, ${user.name}` : undefined}
      secondaryMessage={
        isAuthLoading && !user && !initialLoad
          ? "Logging in..."
          : isLoggingOut
          ? "Redirecting to login..."
          : isReAuthLoading && !isAuthLoading
          ? "Re-Authorizing Email Vendor..."
          : undefined
      }
    >
      {!isContextLoading && (
        <EmailReachDataProvider>
          <SnackbarProvider>{children}</SnackbarProvider>
        </EmailReachDataProvider>
      )}
    </BloxLoadingSplash>
  );
};

export default EmailReachHandler;
